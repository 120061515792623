import React, { useState, useEffect, useContext } from 'react'
import Layout from 'components/AdminDashboard/Layout/Layout'
import {
  Row,
  Column,
  ContentBox,
} from 'components/AdminDashboard/Grid/Grid.styles'
import {
  Card,
  CardTitle,
  CardHeader,
} from 'components/AdminDashboard/Card/Card.styles'
import Button from 'components/Button/Button.styles'
import CountriesMap from 'components/AdminDashboard/CountriesMap/CountriesMap'
import CountriesMapLegend from 'components/AdminDashboard/CountriesMapLegend/CountriesMapLegend'
import RequestInvestorsListModal from 'components/AdminDashboard/RequestInvestorsListModal/RequestInvestorsListModal'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Country from 'components/AdminDashboard/Country/Country'
import Text, { StyledText } from 'components/Text/Text.styles'
import { CountriesMapTooltip } from 'components/AdminDashboard/CountriesMap/CountriesMap.styles'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'
import moment from 'moment'
import {
  Page,
  Text as PdfText,
  View,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer'
import ReactTableWrapper from 'components/AdminDashboard/Table/ReactTable'
import { CryptoAddress, AuthContext, Select } from '@stokr/components-library'
import { getCurrencySymbol } from 'utils/getCurrencySymbol'
import { SelectWrapper } from 'components/Input/Select.styles'
import styled from 'styled-components'
import Project from 'context/ProjectContext/Project'
import { fixDecimals } from 'utils'

const CardHeaderText = styled.p`
  display: block;
  margin-bottom: 24px !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
`

const tableColumns = [
  {
    key: 'email',
    label: 'investor email',
    width: '30%',
  },
  {
    key: 'balance',
    label: 'NUMBER OF SECURITIES',
    width: '20%',
    align: 'center',
  },
  {
    key: 'country',
    label: 'Country',
    width: '20%',
    align: 'left',
  },
  {
    key: 'dropdown',
    label: '',
    width: '20%',
    align: 'center',
  },
]

const tableColumnsTransfers = [
  {
    key: 'txTime',
    label: 'Date',
    width: '20%',
  },
  {
    key: 'txType',
    label: 'Type of movement',
    width: '20%',
  },
  {
    key: 'txAmount',
    label: 'Number of securities moved',
    width: '20%',
  },
  // {
  //   key: 'txAmountAfter',
  //   label: 'Total balance after movement',
  //   width: '20%',
  // },
  // {
  //   key: 'txValue',
  //   label: 'Value of the movement',
  //   width: '20%',
  // },
  {
    key: 'txSender',
    label: 'Transferor',
    width: '20%',
  },
  {
    key: 'txTransferee',
    label: 'Transferee',
    width: '20%',
  },
  // {
  //   key: 'txHash',
  //   label: 'TRANSACTION HASH',
  //   width: '20%',
  // },
]
const countriesMapCategories = {
  default: {
    fill: '#f0f0f0',
    stroke: '#dbdbdb',
    label: '0',
    percent: '100 %',
  },
  from1to10: {
    fill: '#9cb9eb',
    stroke: '#4e76bb',
    label: '1-10',
    percent: '0 %',
  },
  from10to50: {
    fill: '#cefec6',
    stroke: '#7ff36d',
    label: '11-50',
    percent: '0 %',
  },
  from50: {
    fill: '#f8a7a4',
    stroke: '#a73b38',
    label: '50+',
    percent: '0 %',
  },
}

Font.register({
  family: 'Open-Sans',
  src: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap',
})

const styles = StyleSheet.create({
  section: {
    margin: 20,
    padding: 20,
    //flexGrow: 1,
    // fontFamily: 'Open-Sans',
  },
  title: {
    fontSize: 25,
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'capitalize',
    textAlign: 'center',
    marginBottom: 10,
  },
  subtitle: {
    fontWeight: 600,
    marginBottom: 20,
    fontSize: 18,
    textTransform: 'uppercase',
  },
  content: {
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 20,
  },
  tableRow: {
    width: '100%',
    flexDirection: 'row',
  },
  rowContainer: {
    width: '100%',
    height: 30,
  },
  tableWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableRowWrapper: {
    width: '100%',
    height: 30,
  },
  tableCol: {
    width: '12.5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    paddingLeft: 5,
    paddingRight: 5,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    // width: '20%',
  },
  subscript: {
    fontSize: 8,
    verticalAlign: 'super',
    fontWeight: 700,
  },
  footnotesSection: {
    marginTop: 20,
    paddingTop: 10,
  },
  footnotesDivider: {
    borderTopWidth: 1,
    borderColor: '#000',
    width: '25%',
    marginBottom: 10,
  },
  footnoteRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  footnoteNumber: {
    fontSize: 10,
    marginRight: 5,
    verticalAlign: 'super',
  },
  footnoteText: {
    fontSize: 10,
  },
})

export const transferTypes = {
  ISSUANCE: 'Issuance',
  TRANSFER: 'Transfer',
  REDEMPTION: 'Redemption',
  PLEDGE: 'Pledge',
}

export const InvestorRegisterDoc = ({
  holders,
  project,
  selectedDate = new Date(),
}) => {
  const redemptionSale = project?.sales.find((sale) => sale.isRedemption)

  // Create a footnotes tracker object
  const footnotesTracker = {
    notes: {},
    counter: 0,
    getFootnoteNumber(transfer) {
      if (transfer.type !== transferTypes.PLEDGE || !transfer.msg) return null

      // If we already have this transfer's footnote, return its number
      if (this.notes[transfer.recipient]) {
        return this.notes[transfer.recipient].number
      }

      // Create new footnote
      this.counter++
      this.notes[transfer.recipient] = {
        number: this.counter,
        message: transfer.msg,
      }

      return this.counter
    },
    getFootnotes() {
      return Object.entries(this.notes).map(([recipient, note]) => ({
        recipient,
        ...note,
      }))
    },
  }

  return (
    <Document>
      {holders &&
        holders.map((holder, index) => (
          <Page size="A4" orientation="landscape" key={holder.email}>
            <View style={styles.section}>
              {index === 0 && (
                <>
                  <PdfText style={styles.title}>
                    INVESTOR REGISTER - {project.title.toUpperCase()} {'\n'}
                  </PdfText>
                  <PdfText style={{ ...styles.title, fontSize: 18 }}>
                    {moment(selectedDate)
                      .format('DD MMM YYYY - HH:mm')
                      .toUpperCase()}
                  </PdfText>
                  <PdfText
                    style={{
                      ...styles.title,
                      fontSize: 16,
                      marginBottom: 40,
                      textTransform: 'initial',
                    }}
                  >
                    Total amount of {project.tokenSymbol} in circulation:{' '}
                    {getTotalAmountOfTokens(holders, project)}
                  </PdfText>
                </>
              )}
              <PdfText style={styles.subtitle}>Investor information</PdfText>
              {holder.fullName && (
                <View style={styles.content}>
                  <PdfText style={[styles.content]}>
                    Investor name:{'  '}
                  </PdfText>
                  <PdfText style={styles.content}>{holder.fullName}</PdfText>
                </View>
              )}
              <View style={styles.content}>
                <PdfText style={[styles.content]}>
                  Investor email:{'  '}
                </PdfText>
                <PdfText style={styles.content}>{holder.email}</PdfText>
              </View>
              {holder.postalAddress && (
                <View style={styles.content}>
                  <PdfText style={[styles.content]}>
                    Investor postal adress:{'  '}
                  </PdfText>
                  <PdfText style={styles.content}>
                    {holder.postalAddress}
                  </PdfText>
                </View>
              )}
              <View style={styles.content}>
                <PdfText style={[styles.content]}>
                  Investor wallet address:{'  '}
                </PdfText>
                <PdfText style={styles.content}>
                  {holder.wallets
                    .filter((wallet) => !wallet.isPledged)
                    .map((wallet, index) => {
                      let isLastOne =
                        index ===
                        holder.wallets.filter((wallet) => !wallet.isPledged)
                          .length -
                          1
                      if ((index + 1) % 2 === 0)
                        return isLastOne
                          ? wallet.address
                          : wallet.address + ', \n'
                      if (isLastOne) return wallet.address
                      else return wallet.address + ', '
                    })}
                </PdfText>
              </View>

              {holder.wallets.some((wallet) => wallet.isPledged) && (
                <View style={styles.content}>
                  <PdfText style={[styles.content]}>
                    Pledgee wallet address:{'  '}
                  </PdfText>
                  <PdfText style={styles.content}>
                    {holder.wallets
                      .filter((wallet) => wallet.isPledged)
                      .map((wallet, index) => {
                        let isLastOne =
                          index ===
                          holder.wallets.filter((wallet) => wallet.isPledged)
                            .length -
                            1
                        if ((index + 1) % 2 === 0)
                          return isLastOne
                            ? wallet.address
                            : wallet.address + ', \n'
                        if (isLastOne) return wallet.address
                        else return wallet.address + ', '
                      })}
                  </PdfText>
                </View>
              )}
              <View style={styles.content}>
                <PdfText style={[styles.content]}>
                  Number of securities:{'  '}
                </PdfText>
                <PdfText style={styles.content}>{holder.balance}</PdfText>
              </View>
              <View
                style={[
                  styles.content,
                  { marginBottom: project.requiresTaxId ? 5 : 30 },
                ]}
              >
                <PdfText style={[styles.content]}>Country:{'  '}</PdfText>
                <PdfText style={styles.content}>{holder.country}</PdfText>
              </View>

              {project.requiresTaxId && (
                <View style={[styles.content, { marginBottom: 30 }]}>
                  <PdfText style={[styles.content]}>Tax ID:{'  '}</PdfText>
                  <PdfText style={styles.content}>{holder.taxId}</PdfText>
                </View>
              )}

              <View style={styles.table}>
                {/* Table Header */}
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <PdfText style={styles.tableCell}>Date</PdfText>
                  </View>
                  <View style={styles.tableCol}>
                    <PdfText style={styles.tableCell}>Type of movement</PdfText>
                  </View>
                  <View style={styles.tableCol}>
                    <PdfText style={styles.tableCell}>
                      Number of securities moved
                    </PdfText>
                  </View>
                  <View style={styles.tableCol}>
                    <PdfText style={styles.tableCell}>
                      Total balance after movement
                    </PdfText>
                  </View>
                  <View style={styles.tableCol}>
                    <PdfText style={styles.tableCell}>
                      Value of the movement
                    </PdfText>
                  </View>
                  <View style={styles.tableCol}>
                    <PdfText style={styles.tableCell}>Transferor</PdfText>
                  </View>
                  <View style={styles.tableCol}>
                    <PdfText style={styles.tableCell}>Transferee</PdfText>
                  </View>
                  <View style={styles.tableCol}>
                    <PdfText style={styles.tableCell}>Transaction hash</PdfText>
                  </View>
                </View>

                {/* Table Body */}
                <View style={styles.tableWrapper}>
                  {holder.txData?.map((transfer, index) => (
                    <View
                      wrap={false}
                      key={transfer.txid + index}
                      style={styles.tableRow}
                    >
                      <View style={styles.tableCol}>
                        <PdfText style={styles.tableCell}>
                          {moment(transfer.time)
                            .format('DD MMM YYYY - HH:mm')
                            .toUpperCase()}
                        </PdfText>
                      </View>
                      <View style={styles.tableCol}>
                        {transfer.type && (
                          <PdfText style={styles.tableCell}>
                            {transfer.type}{' '}
                            {transfer.type === transferTypes.PLEDGE && (
                              <PdfText
                                style={[styles.tableCell, styles.subscript]}
                              >
                                {footnotesTracker.getFootnoteNumber(transfer)}
                              </PdfText>
                            )}
                          </PdfText>
                        )}
                      </View>
                      <View style={styles.tableCol}>
                        <PdfText style={styles.tableCell}>
                          {transfer.amount}
                        </PdfText>
                      </View>
                      <View style={styles.tableCol}>
                        {
                          <PdfText style={styles.tableCell}>
                            {transfer.balanceAfterTx}
                          </PdfText>
                        }
                      </View>
                      <View style={styles.tableCol}>
                        {project.name === 'blockstream-mining' &&
                        transfer.type === transferTypes.REDEMPTION &&
                        redemptionSale?.bmnInEur ? (
                          <PdfText style={styles.tableCell}>
                            {fixDecimals(
                              transfer.amount * redemptionSale.bmnInEur,
                              2,
                            )}{' '}
                            {getCurrencySymbol(project.tokenCurrency)}
                          </PdfText>
                        ) : (
                          transfer.currencyAmount && (
                            <PdfText style={styles.tableCell}>
                              {fixDecimals(transfer.currencyAmount, 2)}{' '}
                              {getCurrencySymbol(project.tokenCurrency)}
                            </PdfText>
                          )
                        )}
                      </View>
                      <View style={styles.tableCol}>
                        <PdfText style={styles.tableCell}>
                          {transfer.type === transferTypes.ISSUANCE
                            ? 'Issuer'
                            : transfer.sender?.match(/.{0,13}/g).join(' ')}
                        </PdfText>
                      </View>
                      <View style={styles.tableCol}>
                        <PdfText style={styles.tableCell}>
                          {transfer.type === transferTypes.REDEMPTION ? (
                            'Issuer'
                          ) : (
                            <>
                              {transfer.recipient?.match(/.{0,13}/g).join(' ')}
                            </>
                          )}
                        </PdfText>
                      </View>
                      <View style={styles.tableCol}>
                        {transfer.txid && (
                          <PdfText style={styles.tableCell}>
                            {transfer.txid.match(/.{0,14}/g).join(' ')}
                          </PdfText>
                        )}
                      </View>
                    </View>
                  ))}
                </View>
              </View>

              {/* Footnotes Section */}
              {holder.wallets.some((wallet) => wallet.isPledged) &&
                footnotesTracker.counter > 0 && (
                  <View style={styles.footnotesSection}>
                    <View style={styles.footnotesDivider} />
                    {footnotesTracker.getFootnotes().map((footnote) => (
                      <View key={footnote.txid} style={styles.footnoteRow}>
                        <PdfText style={styles.footnoteNumber}>
                          {footnote.number}
                        </PdfText>
                        <PdfText style={styles.footnoteText}>
                          {footnote.message}
                        </PdfText>
                      </View>
                    ))}
                  </View>
                )}
            </View>
          </Page>
        ))}
    </Document>
  )
}

function AdminTokenHolder() {
  const projectContext = useContext(ProjectContext)
  const authContext = useContext(AuthContext)

  const {
    project = {},
    isLoadingInvestorRegister,
    investorRegisterError,
    investorRegister,
    mapData,
  } = projectContext

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [mapList, setMapList] = useState({})
  const [userType, setUserType] = useState()

  useEffect(() => {
    if (projectContext && authContext) {
      const { user } = authContext

      user && setUserType(user.user_type)

      mapData && setMapList(mapData)
    }
  }, [mapData, projectContext, authContext])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleRequestList = async () => {
    setIsModalOpen(true)
  }

  const renderRequestInvestorsListButton = () => {
    if (userType === 'company_sa') {
      return (
        <Button
          withMarginBottom
          onClick={() => handleRequestList()}
          disabled={
            isLoadingInvestorRegister ||
            investorRegisterError ||
            investorRegister.length <= 0
          }
        >
          Download
        </Button>
      )
    }
  }

  return (
    <Layout currentPage="token-holder">
      <Row mainDashboard>
        <Column>
          <ContentBox>
            <Row>
              <Column>
                <Card>
                  <CardHeader>
                    <Text withMarginBottom withMarginRight>
                      <h3>Investor register</h3>
                    </Text>
                    {renderRequestInvestorsListButton()}
                  </CardHeader>

                  <CardHeaderText style={{ fontSize: 13 }}>
                    Total amount of {project.tokenSymbol} in circulation:{' '}
                    {getTotalAmountOfTokens(investorRegister, project)}
                  </CardHeaderText>

                  <ComponentWrapper
                    noPaddingTop
                    noPaddingBottom
                    noPaddingHorizontal
                  >
                    {isLoadingInvestorRegister ? (
                      <>
                        <ComponentWrapper center>
                          <Text blockchainLoadingMsg>
                            <h4>
                              A few seconds left. We are still loading the data
                              <StyledText as={'span'} inheritFontSize>
                                <span data-order="0">.</span>
                                <span data-order="1">.</span>
                                <span data-order="2">.</span>
                              </StyledText>
                            </h4>
                          </Text>
                        </ComponentWrapper>
                      </>
                    ) : investorRegisterError ? (
                      <ComponentWrapper noPaddingHorizontal>
                        <Text>
                          <p>{investorRegisterError}</p>
                        </Text>
                      </ComponentWrapper>
                    ) : investorRegister.length > 0 ? (
                      <>
                        <ReactTableWrapper
                          blue
                          withSubTable
                          columns={tableColumns}
                          data={investorRegister.map((row) => {
                            return {
                              ...row,
                              country: (
                                <Country
                                  key={row.country}
                                  countryAbbreviation={row.countryAbbreviation}
                                >
                                  {row.country}
                                </Country>
                              ),
                            }
                          })}
                          subColumns={tableColumnsTransfers}
                          calculateSubData={(row) => {
                            const investor = investorRegister.find(
                              (investor) =>
                                investor.userId === row?.original?.userId,
                            )

                            return investor?.txData?.map((row) => ({
                              sender: row.sender,
                              recipient: row.recipient,
                              txTime: moment(row.time)
                                .format('DD MMM YYYY - hh:mm')
                                .toUpperCase(),
                              time: row.time,
                              txType: row.type,
                              txSender:
                                row.type === transferTypes.ISSUANCE ? (
                                  'Issuer'
                                ) : row.sender ? (
                                  <CryptoAddress
                                    noHead
                                    fontSize={14}
                                    data={{
                                      value: row.sender,
                                      tooltip: true,
                                      shortAddress: true,
                                    }}
                                  />
                                ) : (
                                  '-'
                                ),
                              txTransferee:
                                row.type === transferTypes.REDEMPTION ? (
                                  'Issuer'
                                ) : row.recipient ? (
                                  <CryptoAddress
                                    noHead
                                    fontSize={14}
                                    data={{
                                      value: row.recipient,
                                      tooltip: true,
                                      shortAddress: true,
                                    }}
                                  />
                                ) : (
                                  '-'
                                ),
                              txAmount: row.amount.toLocaleString('en', {
                                minimumFractionDigits: project.tokenDecimals,
                              }),
                            }))
                          }}
                        />
                      </>
                    ) : (
                      <Text>
                        <p>
                          Data will appear after the end of the offering when
                          the tokens are distributed
                        </p>
                      </Text>
                    )}
                  </ComponentWrapper>
                </Card>
              </Column>

              {!isLoadingInvestorRegister && (
                <Column>
                  <Card>
                    <CardTitle>Countries by number of holders</CardTitle>
                    {Object.entries(mapList).length > 0 ? (
                      <>
                        <ComponentWrapper noPaddingBottom noPaddingHorizontal>
                          <CountriesMap
                            categories={countriesMapCategories}
                            tooltip
                            countryMapping={(country) => {
                              const countryObj = {
                                ...country,
                                properties: {
                                  ...country.properties,
                                  category: 'default',
                                  investors: 0,
                                  value: 0,
                                  ...mapList[country.id],
                                },
                              }
                              countryObj.properties.tooltip =
                                createCountriesMapTooltip(countryObj)
                              return countryObj
                            }}
                            handleClick={(e) => {
                              console.log(e) // eslint-disable-line no-console
                            }}
                          />
                        </ComponentWrapper>
                        <ComponentWrapper noPaddingBottom noPaddingHorizontal>
                          <CountriesMapLegend
                            categories={countriesMapCategories}
                          />
                        </ComponentWrapper>
                      </>
                    ) : (
                      <>
                        <br />
                        <Text>
                          <p>No data yet</p>
                        </Text>
                      </>
                    )}
                  </Card>
                </Column>
              )}
            </Row>
          </ContentBox>
        </Column>
      </Row>
      {isModalOpen && (
        <RequestInvestorsListModal
          isModalOpen={isModalOpen}
          onModalClose={closeModal}
          investorRegister={investorRegister && investorRegister}
          project={project}
        />
      )}
    </Layout>
  )
}

const getTotalAmountOfTokens = (investorRegister, project) => {
  let total =
    investorRegister.reduce((acc, cur) => acc + cur.intBalance, 0) /
    Math.pow(10, project.tokenDecimals)
  return total.toLocaleString('en', {
    minimumFractionDigits: project.tokenDecimals,
  })
}

const createCountriesMapTooltip = (country) => (
  <CountriesMapTooltip>
    <h3>{country.properties.name}</h3>
    <h4>{`${country.properties.investors} investors`}</h4>
    <p>{`(${
      country.properties.currencySymbol
    }${country.properties.value.toLocaleString()})`}</p>
  </CountriesMapTooltip>
)

export default AdminTokenHolder
